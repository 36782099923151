<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import Toggle from "svelte-toggle"
    import {createEventDispatcher} from "svelte"

    export let entry: FormEntry

    const dispatch = createEventDispatcher()

    const handleToggle = (e: CustomEvent<boolean>): void => {
        entry.checked = e.detail
        dispatch("toggle", e.detail)
    }
</script>


<div {...entry.rowAttr} class="flex flex-col {entry.rowAttr?.class ?? ''}">
    <div class="flex items-center">
        <div class="msd-checkbox-toggle-container scale-110 relative left-1">
            <Toggle
                    small={entry.attr?.small ?? false}
                    disabled={entry.disabled}
                    hideLabel={true}
                    id={entry.id}
                    on:toggle={handleToggle}
                    toggled={entry.checked}
                    toggledColor="#EEBA4C"
            />
        </div>
        {#if entry.checked}
            <div class="hidden">
                <input
                        {...entry.attr}
                        autoComplete="off"
                        checked="checked"
                        id={entry.id}
                        name={entry.fullName}
                        on:change
                        tabIndex="-1"
                        type={entry.type}
                        value={entry.value}
                />
            </div>
        {/if}

        {#if $$slots.label}
            <slot name="label"/>
        {:else }
            {#if entry.label !== null}
                {#if entry.extra?.url}
                    <a href="{entry.extra?.url}" class="ml-4 font-bold text-neutral-800 underline">
                        {entry.label}
                    </a>
                {:else}
                    <label {...entry.labelAttr} class="ml-4 font-bold text-neutral-800 {entry.labelAttr?.class ?? ''}">
                        {@html entry.label}
                    </label>
                {/if}
            {/if}
        {/if}

    </div>

    {#if entry.help}
        <small class="mt-3">{@html entry.help}</small>
    {/if}

    <FormTypeErrors errors={entry.errors}/>
</div>

<style lang="scss">
    .msd-checkbox-toggle-container {
        button[role="switch"] :focus {
            outline: none;
        }
    }
</style>
